import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './SupportTools.module.scss';
import { useTranslation } from 'react-i18next';
import {
  useGetIcon,
  useGetImageForLanguage,
  useSetTreatmentPlan,
} from '../../../../hooks';
import { useLocation } from 'react-router-dom';
import { Modal, VideoPlayer } from '../../../../components';
import { getUrl } from 'aws-amplify/storage';

type SupportToolsProps = {
  treatmentPlan: any;
};

export const SupportTools: FC<SupportToolsProps> = ({ treatmentPlan }) => {
  const carouselSupportTools = useRef<any>(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    i18n: { language },
  } = useTranslation();
  const cleanRoute = (str: string) => {
    return str.replace(/(^\/|\/$)/g, '');
  };

  const cleanPathName = cleanRoute(pathname);
  const { supportToolsCards, referencesList } = useSetTreatmentPlan(
    cleanPathName,
    language
  );

  // LEAFLET PDF
  useEffect(() => {
    const videoPath = t(`turbohalerVideo.videoPath`);
    async function getSignedUrl() {
      const link = await getUrl({
        path: videoPath,
        options: { expiresIn: 60 * 60 }, // 1 hour
      });
      setLinkToVideo(link);
    }

    getSignedUrl();
  }, [language]);

  // GET VIDEO DETAILS
  const [linkToVideo, setLinkToVideo] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  // CONFIGURE VIDEO PLAYER
  const playerRef = React.useRef(null);
  const videoPlayerOptions = {
    autoplay: showModal,
    controls: true,
    responsive: true,
    fluid: true,
    poster: useGetImageForLanguage(
      language,
      'myFirstInhaler/videoThumbnail.png'
    ),
    posterText: t(`turbohalerVideo.videoText`),
    sources: [
      {
        src: linkToVideo?.url.href,
        type: 'video/mp4',
      },
    ],
  };
  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
  };

  const chevronRight = useGetIcon('chevron-right.png');
  const arrowLeft = useGetIcon('arrow_left.png');
  const arrowRight = useGetIcon('arrow_right.png');

  return (
    <>
      <div className={styles.supportTools} data-testid="SupportTools">
        <section id="supportTools" className={styles.supportToolsContainer}>
          <small>{t(`${treatmentPlan}.supportToolsPreTitle`)}</small>

          <h1>{t(`${treatmentPlan}.supportToolsTitle`)}</h1>

          <p>{t(`${treatmentPlan}.supportToolsStrong`)}</p>

          <div className={styles.cardContainer} ref={carouselSupportTools}>
            {supportToolsCards.map((data) => (
              <div className={styles.card} key={data.title}>
                <div className={styles.iconText}>
                  <img src={data.icon} alt="checks" />
                  <p>{data.iconText}</p>
                </div>

                <h2 className={styles.cardTitle}>{data.title}</h2>
                <p className={styles.cardBody}>{data.description}</p>

                <div className={styles.cardFooter}>
                  {data.iconText === 'Video' ? (
                    <button onClick={() => setShowModal(true)}>
                      {data.linkLabel}
                    </button>
                  ) : (
                    <a href={data.asset} target="_blank">
                      {data.linkLabel}
                    </a>
                  )}
                  <img src={chevronRight} alt="chevron right" />
                </div>
              </div>
            ))}
          </div>

          {/* BUTTOV2 TO MOVE LEFT OR RIGHT ON MOBILE MODE ONLY */}
          <div className={styles.buttonsContainer}>
            <button
              className={styles.left}
              onClick={() => {
                if (carouselSupportTools.current.scrollLeft == 0) {
                  return;
                }
                return carouselSupportTools.current.scrollBy({
                  left: -320,
                  behavior: 'smooth',
                });
              }}
              data-testid="leftArrowButton"
            >
              <img src={arrowLeft} alt="arrow left" />
            </button>

            <button
              className={styles.right}
              onClick={() => {
                if (carouselSupportTools.current.scrollLeft > 500) {
                  return;
                }
                return carouselSupportTools.current.scrollBy({
                  left: 320,
                  behavior: 'smooth',
                });
              }}
              data-testid="rightArrowButton"
            >
              <img src={arrowRight} alt="arrow right" />
            </button>
          </div>

          <div className={styles.leftBorder}>
            {t(`${treatmentPlan}.supportToolsFooter`)}
          </div>

          {/* =========== DISCLAIMER ========== */}
          <div className={styles.disclaimer}>
            <h4>{t(`${treatmentPlan}.disclaimerTitle`)}</h4>
            <p>{t(`${treatmentPlan}.disclaimer`)}</p>
          </div>

          {/* =========== REFERENCES ========== */}
          <div className={styles.references}>
            <h4>{t(`${treatmentPlan}.references`)}</h4>
            <ol>
              {referencesList.map((reference) => (
                <li key={reference}>{reference}</li>
              ))}
            </ol>
          </div>
        </section>
      </div>

      {linkToVideo && (
        <Modal handleClose={() => setShowModal(false)} show={showModal}>
          <VideoPlayer
            options={videoPlayerOptions}
            onReady={handlePlayerReady}
          />
        </Modal>
      )}
    </>
  );
};
