import React, { Suspense } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { routes, privateRoutes } from './routes';
import { PrivateRoute } from './PrivateRoute';
import { Loading } from '../../components/Loading/Loading';
import AnalyticsTracker from '../analyticsTracker/AnalyticsTracker';

export default function AppRouter() {
  return (
    <Suspense fallback={<Loading className="loading" />}>
      <BrowserRouter>
        <AnalyticsTracker />
        <Routes>
          {routes.map(({ path, Component, children }) => (
            <Route key={path} path={path} element={<Component />}>
              {children &&
                children.map(({ path, Component }) => (
                  <Route key={path} path={path} element={<Component />} />
                ))}
            </Route>
          ))}

          {privateRoutes.map(({ path, Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute>
                  <Component />
                </PrivateRoute>
              }
            />
          ))}
          <Route path="/*" element={<Navigate to={routes[0].to} replace />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
