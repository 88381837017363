import React from 'react';
import videojs from 'video.js';
import { useTranslation } from 'react-i18next';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';
import './VideoPlayer.scss';

export const VideoPlayer = (props: any) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef<Player | null>(null);
  const textDivRef = React.useRef<HTMLSpanElement | null>(null); // Reference for textDiv
  const { options, onReady } = props;
  const { t, i18n } = useTranslation();

  const updatePlayerText = React.useCallback(() => {
    if (playerRef.current) {
      // Update big play button text
      const bigPlayButton = playerRef.current.getChild('bigPlayButton');
      if (bigPlayButton) {
        bigPlayButton.el().innerHTML = t('turbohalerVideo.videoButton');
      }

      // Update poster text if textDiv exists
      if (textDivRef.current) {
        textDivRef.current.innerHTML = props.isV2
          ? t('turbohalerVideo.videoTextV2')
          : t('turbohalerVideo.videoText');
      }
    }
  }, [t]);

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-player', 'video-js');
      if (videoRef.current) {
        (videoRef.current as HTMLDivElement).appendChild(videoElement);
      }

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady?.(player);
        updatePlayerText(); // Set initial text
      }));

      // Add overlay to poster
      const posterOverlay = document.createElement('div');
      posterOverlay.className = 'vjs-poster-overlay';
      const posterImage = player.getChild('posterImage');
      if (posterImage) {
        posterImage.el().appendChild(posterOverlay);
      }

      // Add poster text if provided
      if (
        props.options.posterText !== undefined &&
        props.options.posterText !== 'turbohalerVideo.videoText'
      ) {
        const textDiv = document.createElement('span');
        textDiv.className = 'vjs-poster-text';
        textDiv.innerHTML = props.isV2
          ? t('turbohalerVideo.videoTextV2')
          : t('turbohalerVideo.videoText');
        textDivRef.current = textDiv; // Store reference to textDiv

        if (posterImage) {
          posterImage.el().appendChild(textDiv);
        }
      } else {
        const bigPlayButton = player.getChild('bigPlayButton');
        if (bigPlayButton) {
          bigPlayButton.el().classList.add('vjs-big-play-centered');
        }
      }
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef, updatePlayerText]);

  React.useEffect(() => {
    i18n.on('languageChanged', updatePlayerText);

    return () => {
      i18n.off('languageChanged', updatePlayerText);
    };
  }, [i18n, updatePlayerText]);

  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player data-testid="VideoPlayer">
      <div ref={videoRef} />
    </div>
  );
};

export default VideoPlayer;
