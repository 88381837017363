import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSetTreatmentPlan } from '../../../../hooks';
import styles from './References.module.scss';

type ReferencesProps = {
  treatmentPlan: any;
  isV2?: boolean;
};

export const References: FC<ReferencesProps> = ({ treatmentPlan, isV2 }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { pathname } = useLocation();

  const cleanRoute = (str: string) => {
    return str.replace(/(^\/|\/$)/g, '');
  };

  const cleanPathName = cleanRoute(pathname.split('/')[1]); // V2

  const { referencesList } = useSetTreatmentPlan(cleanPathName, language, isV2);

  return (
    <div className={styles.referencesContainer}>
      <section data-testid="References">
        {/* =========== REFERENCES ========== */}
        <div className={styles.references}>
          <h4>{t(`${treatmentPlan}.references`)}</h4>
          <ol>
            {referencesList.map((reference) => (
              <li key={reference}>{reference}</li>
            ))}
          </ol>
        </div>
      </section>
    </div>
  );
};
