import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/icons/a2b_blue_logo.png';
import styles from './Header.module.scss';

type HeaderProps = {
  isV2: boolean;
};

export const Header: FC<HeaderProps> = ({ isV2 }) => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const handleChangeLanguage = (e: any) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div className={styles.header} data-testid="Header">
      {/* ADVERSE EVENT  */}
      <div className={styles.adverseEventContainer}>
        <span data-testid="adverseEvent">
          {isV2 ? t('header.adverseEventV2') : t('header.adverseEvent')}
        </span>
        <a
          href="https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/eg/en/amp-form.html"
          target="_blank"
          data-testid="reportAdverseEvent"
          className={styles.buttonReportAdverse}
        >
          {t('header.buttonAdverseEvent')}
        </a>

        <select
          className={styles.changeLanguage}
          value={i18n.language.slice(0, 2)}
          onChange={handleChangeLanguage}
        >
          <option value="en">EN</option>
          <option value="es">ES</option>
        </select>
      </div>
      <div className={styles.headerContainer}>
        {/* ============= LOGO ============== */}
        <Link to={'/'} relative="path" className={styles.logoContainer}>
          <img src={logo} alt="air 2 breathe logo" className={styles.logo} />
        </Link>
      </div>
    </div>
  );
};
