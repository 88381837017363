import React, { FC, useEffect, useState } from 'react';
import styles from './SideEffects.module.scss';
import { useTranslation } from 'react-i18next';
import { getUrl } from 'aws-amplify/storage';
import { useSetTreatmentPlan } from '../../../../hooks';
import { useLocation } from 'react-router-dom';

type SideEffectsProps = {
  treatmentPlan: any;
};
export const SideEffects: FC<SideEffectsProps> = ({ treatmentPlan }) => {
  const { t } = useTranslation();
  const [linkToLeaflet, setLinkToLeaflet] = useState<any>(null);
  const { pathname } = useLocation();

  const leafletPath = t(`patientInformationLeaflet.leafletPath`);
  const cleanRoute = (str: string) => {
    return str.replace(/(^\/|\/$)/g, '');
  };

  const cleanPathName = cleanRoute(pathname);

  const { sideEffectTabs } = useSetTreatmentPlan(cleanPathName);

  useEffect(() => {
    async function getSignedLeafletUrl() {
      const pdf = await getUrl({
        path: leafletPath,
        options: { expiresIn: 60 * 60 }, // 1 hour
      });
      setLinkToLeaflet(pdf?.url.href);
    }

    getSignedLeafletUrl();
  }, []);
  const [tabId, setTabId] = useState('commonSideEffects');

  const handleSetTab = (tabId: string) => {
    setTabId(tabId);
  };

  return (
    <div className={styles.sideEffects} data-testid="SideEffects">
      <section id="sideEffects" className={styles.sideEffectsContainer}>
        <small> {t(`${treatmentPlan}.sideEffectsPreTitle`)} </small>
        <h1>{t(`${treatmentPlan}.sideEffectsTitle`)} </h1>

        <div className={styles.twoBoxes}>
          <div className={styles.flexBasisHalf}>
            <h3>{t(`${treatmentPlan}.sideEffectsStrong`)}</h3>
          </div>
          <div className={styles.flexBasisHalf}>
            <p>
              {t(`${treatmentPlan}.sideEffectsParagraph1Leading`)}

              <a href={linkToLeaflet} target="_blank">
                {t(`${treatmentPlan}.sideEffectsParagraphLink`)}
              </a>
              {t(`${treatmentPlan}.sideEffectsParagraph1Trailing`)}
            </p>

            <p>
              <strong>
                {t(`${treatmentPlan}.sideEffectsParagraphStrong`)}
              </strong>
            </p>
          </div>
        </div>

        <div>
          <div className={styles.tab}>
            {sideEffectTabs.map((tab) => (
              <button
                key={tab.title}
                style={tabId == tab.id ? { backgroundColor: 'white' } : {}}
                onClick={() => handleSetTab(tab.id)}
              >
                <strong>{tab.title.split(',')[0]} </strong>
              </button>
            ))}
          </div>
          {sideEffectTabs.map((tab) => (
            <div
              key={tab.title}
              style={
                tabId == tab.id
                  ? {
                      display: 'flex',
                      flexDirection: 'column',
                      boxShadow: '0px 8px 16px 0px rgba(60, 66, 66, 0.15)',
                    }
                  : {}
              }
              className={styles.tabContent}
            >
              <div className={styles.tabSubtitle}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(tab.title),
                  }}
                />
              </div>

              <hr />

              <ul>
                {tab.list.map((li: any) => (
                  <li key={li} className={styles.tabList}>
                    {li}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className={styles.leftBorder}>
          {t(`${treatmentPlan}.sideEffectsFooter`)}
          <sup>2</sup>
        </div>
      </section>
    </div>
  );
};
