import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './SupportTools.module.scss';
import { useTranslation } from 'react-i18next';
import {
  useGetIcon,
  useGetImageForLanguage,
  useSetTreatmentPlan,
} from '../../../../hooks';
import { useLocation } from 'react-router-dom';
import { Modal, VideoPlayer } from '../../../../components';
import { getUrl } from 'aws-amplify/storage';

type SupportToolsProps = {
  treatmentPlan: any;
};

export const SupportTools: FC<SupportToolsProps> = ({ treatmentPlan }) => {
  const carouselSupportTools = useRef<any>(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    i18n: { language },
  } = useTranslation();
  const cleanRoute = (str: string) => {
    return str.replace(/(^\/|\/$)/g, '');
  };

  const cleanPathName = cleanRoute(pathname.split('/')[1]); // V2

  const { supportToolsCards } = useSetTreatmentPlan(
    cleanPathName,
    language,
    true
  );

  // LEAFLET PDF
  useEffect(() => {
    const videoPath = t(`turbohalerVideo.videoPath`);

    async function getSignedUrl() {
      const link = await getUrl({
        path: videoPath,
        options: { expiresIn: 60 * 60 }, // 1 hour
      });
      setLinkToVideo(link);
    }

    getSignedUrl();
  }, [language]);

  // GET VIDEO DETAILS
  const [linkToVideo, setLinkToVideo] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  // CONFIGURE VIDEO PLAYER
  const playerRef = React.useRef(null);
  const videoPlayerOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    poster: useGetImageForLanguage(
      language,
      'myFirstInhaler/videoThumbnail.png'
    ),
    posterText: t(`turbohalerVideo.videoText`),
    sources: [
      {
        src: linkToVideo?.url.href,
        type: 'video/mp4',
      },
    ],
  };
  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
  };

  const chevronRight = useGetIcon('chevron-right.png');

  const handleOpenAsset = (data: string) => {
    if (data) {
      const url = data;
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      link.click();
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <div className={styles.supportTools} data-testid="SupportTools">
        <section id="supportTools" className={styles.supportToolsContainer}>
          <h1>{t(`${treatmentPlan}.supportToolsTitle`)}</h1>

          <p className={styles.supportToolsStrong}>
            {t(`${treatmentPlan}.supportToolsStrongV2`)}
          </p>

          <div className={styles.cardContainer} ref={carouselSupportTools}>
            {supportToolsCards.map((data) => (
              <div
                className={styles.card}
                key={data.title}
                onClick={() => handleOpenAsset(data.asset)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault(); // Prevent default scrolling behavior for Space key
                    handleOpenAsset(data.asset);
                  }
                }}
              >
                <div className={styles.iconText}>
                  <img
                    className={styles.cardIcon}
                    src={data.icon}
                    alt="checks"
                  />
                  <p className={styles.cardIconText}>{data.iconText}</p>
                </div>

                <h2 className={styles.cardTitle}>{data.title}</h2>
                <p className={styles.cardBody}>{data.description}</p>

                <div className={styles.cardFooter}>
                  {data.iconText === 'video' ? (
                    <button onClick={() => setShowModal(true)}>
                      {data.linkLabel}
                    </button>
                  ) : (
                    <a href={data.asset} target="_blank">
                      {data.linkLabel}
                    </a>
                  )}
                  <img src={chevronRight} alt="chevron right" />
                </div>
              </div>
            ))}
          </div>

          <div className={styles.leftBorder}>
            {t(`${treatmentPlan}.supportToolsFooterV2`)}
          </div>
        </section>
      </div>

      {linkToVideo && (
        <Modal handleClose={() => setShowModal(false)} show={showModal}>
          <VideoPlayer
            options={videoPlayerOptions}
            onReady={handlePlayerReady}
            isV2={true}
          />
        </Modal>
      )}
    </>
  );
};
