import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetIcon } from '../../../../hooks';
import styles from './Introduction.module.scss';
import { Animation } from '../../../../components/Animation/Animation';

type IntroductionProps = {
  themePage: any;
  treatmentPlan: any;
};

export const Introduction: FC<IntroductionProps> = ({
  themePage,
  treatmentPlan,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.introduction}
      style={{ backgroundColor: themePage.backgroundColor }}
      data-testid="Introduction"
    >
      <section id="introduction">
        <div className={styles.lungsImage}>
          <img src={useGetIcon('lungs.png')} loading="lazy" alt="lungs" />
        </div>
        <div className={styles.parallaxContainer}>
          <div className={styles.parallaxContent}>
            <h1>{t(`${treatmentPlan}.introductionTitle`)}</h1>

            <div className={styles.infoContainer}>
              <div className={styles.symbicortInfo}>
                <div>
                  <img src={useGetIcon('check.png')} alt="timer" />
                </div>
                <div>
                  <h3>
                    <strong> {t(`${treatmentPlan}.preventer`)} </strong>
                  </h3>
                  <p>{t(`${treatmentPlan}.preventerParagraph`)}</p>
                </div>
              </div>

              <div className={styles.symbicortInfo}>
                <div>
                  <img
                    src={useGetIcon('timer.png')}
                    loading="lazy"
                    alt="check"
                  />
                </div>
                <div>
                  <h3>
                    <strong> {t(`${treatmentPlan}.reliever`)} </strong>{' '}
                  </h3>
                  <p>{t(`${treatmentPlan}.relieverParagraph`)}</p>
                </div>
              </div>
            </div>
          </div>
          <h1 className={styles.animationTitle}>
            {t(`${treatmentPlan}.inhaledSteroidsTitle`)}
          </h1>
          <div className={styles.animation}>
            <div>
              <Animation animation={'Animation1'} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
