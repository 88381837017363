import React, { FC, useEffect, useState } from 'react';
import { getUrl } from 'aws-amplify/storage';
import { useTranslation } from 'react-i18next';
import { useGetImageForLanguage } from '../../../../hooks';
import { Lottie, VideoPlayer } from '../../../../components';
import styles from './HowToUse.module.scss';
import { Animation } from '../../../../components/Animation/Animation';

type HowToUseProps = {
  treatmentPlan: any;
};

export const HowToUse: FC<HowToUseProps> = ({ treatmentPlan }) => {
  const { t } = useTranslation();

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const videoPath = t(`turbohalerVideo.videoPath`);

    async function getSignedUrl() {
      const link = await getUrl({
        path: videoPath,
        options: { expiresIn: 60 * 60 }, // 1 hour
      });
      setLinkToVideo(link);
    }

    getSignedUrl();
  }, [language]);

  //GET VIDEO DETAILS
  const [linkToVideo, setLinkToVideo] = useState<any>(null);

  //CONFIGURE VIDEO PLAYER
  const playerRef = React.useRef(null);
  const videoPlayerOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    poster: useGetImageForLanguage(
      language,
      'myFirstInhaler/videoThumbnail.png'
    ),
    posterText: t(`turbohalerVideo.videoText`),
    sources: [
      {
        src: linkToVideo?.url.href,
        type: 'video/mp4',
      },
    ],
  };
  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
  };

  return (
    <div className={styles.howToUse} data-testid="HowToUse">
      <section id="howToUse" className={styles.howToUseContainer}>
        <div className={styles.parallaxContainer}>
          <div className={styles.parallaxContent}>
            <h1 className={styles.title}>
              {t(`${treatmentPlan}.howToUseTitleV2`)}
            </h1>

            <p>
              <strong>
                {t(`${treatmentPlan}.howToUseProperUse`)}
                <sup>6</sup>
              </strong>
            </p>

            <p>{t(`${treatmentPlan}.howToUseBreathActivatedV2`)}</p>
          </div>
          <h1 className={styles.animationTitle}>
            {t(`${treatmentPlan}.howToUseHowItWorks`)}
          </h1>
          <div className={styles.animation}>
            <div
              id="1"
              style={{
                position: 'sticky',
                // height: '100vh',
              }}
            >
              <Animation animation={'Animation3'} />
            </div>
          </div>
        </div>

        <h1 className={styles.videoTitle}>
          {t(`${treatmentPlan}.howToUseWatchVideo`)}
        </h1>

        {linkToVideo && (
          <div className={styles.videoPlayer}>
            <VideoPlayer
              options={videoPlayerOptions}
              onReady={handlePlayerReady}
              isV2={true}
            />
          </div>
        )}
      </section>
    </div>
  );
};
