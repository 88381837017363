import { LazyExoticComponent } from 'react';
import { Home, TreatmentPlans } from '../../pages';
import { Blocked } from '../../components';
import TreatmentPlansV2 from '../../pages/TreatmentPlansv2/TreatmentPlans';

interface Route {
  to: string;
  path: string;
  Component: LazyExoticComponent<any> | any;
  name: string;
  children?: Route[];
}

export const routes: Route[] = [
  {
    to: '/',
    path: '/',
    Component: Home,
    name: 'Web',
  },
  {
    to: '/my-first-inhaler',
    path: '/my-first-inhaler',
    Component: TreatmentPlans,
    name: 'MyFirstInhaler',
  },
  {
    to: '/only-reliever',
    path: '/only-reliever',
    Component: TreatmentPlans,
    name: 'OnlyReliever',
  },
  {
    to: '/reliever-and-preventer',
    path: '/reliever-and-preventer',
    Component: TreatmentPlans,
    name: 'RelieverAndPreventer',
  },
  {
    to: '/something-else',
    path: '/something-else',
    Component: TreatmentPlans,
    name: 'SomethingElse',
  },
  {
    to: '/blocked',
    path: '/blocked',
    Component: Blocked,
    name: 'Blocked',
  },
  //DESIGN V2
  {
    to: '/my-first-inhaler/v2',
    path: '/my-first-inhaler/v2',
    Component: TreatmentPlansV2,
    name: 'MyFirstInhalerV2',
  },
  {
    to: '/only-reliever/v2',
    path: '/only-reliever/v2',
    Component: TreatmentPlansV2,
    name: 'OnlyRelieverV2',
  },
  {
    to: '/reliever-and-preventer/v2',
    path: '/reliever-and-preventer/v2',
    Component: TreatmentPlansV2,
    name: 'RelieverAndPreventer',
  },
  {
    to: '/something-else/v2',
    path: '/something-else/v2',
    Component: TreatmentPlansV2,
    name: 'SomethingElse',
  },
];

// const Private = lazy(
//   () =>
//     import(
//       /* webpackChunkName: "Students" */ '../../components/Dashboard/Dashboard'
//     )
// );

export const privateRoutes: Route[] = [
  //   {
  //     to: '/dashboard',
  //     path: '/dashboard',
  //     Component: Private,
  //     name: 'Private',
  //   },
];
