import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Utag {
  view: (data: Record<string, unknown>) => void;
}

declare global {
  interface Window {
    utag?: Utag;
    utag_data?: Record<string, unknown>;
  }
}

const AnalyticsTracker: React.FC = () => {
  const location = useLocation();
  let pageName = location.pathname.substring(1);
  if (pageName === '') {
    pageName = 'Home';
  }
  
  useEffect(() => {
    const core_language = navigator.language;
    const core_target_country = core_language.split('-')[1] || 'US'; //

    const utagData = {
      page_name: 'Air2Breathe ' + pageName,
      page_brand: 'Symbicort',
      page_url: window.location.href,
      page_path: location.pathname,
      core_language: core_language,
      core_target_country: core_target_country,
    };

    window.utag_data = utagData;

    if (window.utag && typeof window.utag.view === 'function') {
      window.utag.view(utagData);
    } else {
      console.warn('Tealium not available');
    }
  }, [location]);

  return null;
};

export default AnalyticsTracker;
