import React, { useState, useEffect, useRef, FC } from 'react';
import { throttle } from 'lodash';

type AnimationProps = {
  animation: 'Animation1' | 'Animation2' | 'Animation3';
};

interface Animation {
  path: string;
  totalFrames: number;
}

const animations: Record<string, Animation> = {
  Animation1: {
    path: '/assets/frames01',
    totalFrames: 194,
  },
  Animation2: {
    path: '/assets/frames02',
    totalFrames: 463,
  },
  Animation3: {
    path: '/assets/frames03',
    totalFrames: 340,
  },
};

const FPS = 24;
const frameTime = 1000 / FPS;

export const Animation: FC<AnimationProps> = ({ animation }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentFrameNumber, setCurrentFrameNumber] = useState<number>(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadedImages, setLoadedImages] = useState<number>(0);

  const preloadImages = async (animationKey: string) => {
    const anim = animations[animationKey];
    const promises = Array.from(
      { length: anim.totalFrames },
      (_, i) =>
        new Promise<void>((resolve, reject) => {
          const img = new Image();
          img.src = `${anim.path}/frame_${String(i).padStart(3, '0')}.webp`;
          img.onload = () => {
            setLoadedImages((prev) => prev + 1);
            resolve();
          };
          img.onerror = reject;
        })
    );

    await Promise.all(promises);
    setImagesLoaded(true);
  };

  const handleGlobalScroll = throttle(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const { top, height } = container.getBoundingClientRect(); // Get the position of the component in the viewport

    const windowHeight = window.innerHeight;
    const start = Math.max(0, -top); // Component enters the viewport
    const end = Math.min(height, windowHeight); // Component leaves the viewport

    const progress = Math.max(
      0,
      Math.min(start / (height - windowHeight + end), 1)
    ); // Normalize progress between 0 and 1

    const totalFrames = animations[animation].totalFrames;
    const frame = Math.round(progress * (totalFrames - 1));
    setCurrentFrameNumber(Math.min(Math.max(frame, 0), totalFrames - 1));
  }, frameTime);

  useEffect(() => {
    preloadImages(animation);

    window.addEventListener('scroll', handleGlobalScroll);

    return () => {
      window.removeEventListener('scroll', handleGlobalScroll);
      handleGlobalScroll.cancel();
    };
  }, [animation]);

  return (
    <div
      ref={containerRef}
      style={{
        height: '800vh', // Large height to test scroll animations
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'sticky',
          top: '10vh',
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
          zIndex: 1,
          overflow: 'hidden',
        }}
      >
        {!imagesLoaded ? (
          <div style={{ textAlign: 'center' }}>
            Loading frames... (
            {Math.round(
              (loadedImages / animations[animation].totalFrames) * 100
            )}
            %)
          </div>
        ) : (
          <img
            src={`${animations[animation].path}/frame_${String(currentFrameNumber).padStart(3, '0')}.webp`}
            alt={`Frame ${currentFrameNumber}`}
            style={{
              width: '100%',
              maxWidth: '600px',
              height: 'auto',
              objectFit: 'contain',
              display: 'block',
              padding: '20px',
              userSelect: 'none',
              WebkitUserSelect: 'none',
            }}
          />
        )}
      </div>
    </div>
  );
};
