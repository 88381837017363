import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EnumSpanishCountriesCode,
  EnumEnglishCountriesCode,
} from './models/countriesCode.enum';
import styles from './DisclaimerPopUp.module.scss';

type DisclaimerPopUpProps = {
  closeTab: () => void;
  isV2?: boolean;
};

export const DisclaimerPopUp: FC<DisclaimerPopUpProps> = ({
  closeTab,
  isV2,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [codes, setCodes] = useState<any[]>([]);

  const handleChangeLanguage = (e: any) => {
    i18n.changeLanguage(e.target.value);
  };

  const handleSelectOptions = () => {
    setCodes([]);
    const countries = t('countries', {
      returnObjects: true,
    }) as string[];

    let countriesCode: any;

    if (i18n.language.includes('en')) {
      countriesCode = EnumEnglishCountriesCode;
    } else {
      countriesCode = EnumSpanishCountriesCode;
    }

    countries.map((country: string) => {
      setCodes((prev) => [
        ...prev,
        { value: countriesCode[country], name: country },
      ]);
    });
  };

  useEffect(() => {
    handleSelectOptions();
  }, [i18n.language]);

  return (
    <div className={styles.disclaimerContainer} data-testid="DisclaimerPopUp">
      <h1> {t('disclaimer.title')}</h1>

      <p>
        {t('disclaimer.body')}{' '}
        <select
          className={styles.changeLanguage}
          value={i18n.language}
          onChange={handleChangeLanguage}
        >
          {codes.length > 0 &&
            codes.map((code: any) => (
              <option key={code.value} value={code.value}>
                {code.name}
              </option>
            ))}
        </select>
        {isV2 ? t('disclaimer.bodyTrailingV2') : t('disclaimer.bodyTrailing')}
      </p>

      <button className={styles.buttonReportAdverse} onClick={closeTab}>
        {isV2 ? t('disclaimer.buttonV2') : t('disclaimer.button')}
      </button>
    </div>
  );
};
